import React from "react";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import styled, { keyframes } from "styled-components";
import SEO from "../components/core/Seo";
import BaseLayout from "../components/core/BaseLayout";
import RatesSVG from "../assets/images/svg/ratessarah.svg";

import {
  WaveFooter,
  Section,
  Content,
  FeatureDuo,
  Headline,
  Subline,
  Text,
  Visualization,
  TextLinkExternal,
} from "../components/SubComponents";

import { LINK_GRAVY, LINK_SPRECHERPREISE } from "../constants/linkdata";

const cogAnimation = keyframes`
  100% { transform: rotate(360deg) }
`;
const cogAnimationTwo = keyframes`
  100% { transform: rotate(-360deg) }
`;
const plantAnimation = keyframes`
  40% {transform: skew(-1deg, -2deg) rotate(-4deg);}
  70% {transform: skew(1deg, 2deg) rotate(-7deg);}
`;

const SarahRates = styled(RatesSVG)`
  .cogOne {
    transform-origin: 1278px 312px;
    animation: ${cogAnimation} linear 7s infinite;
  }
  .cogTwo {
    transform-origin: 275.5px 478px;
    animation: ${cogAnimationTwo} linear 8s infinite;
  }
  .bookPlant {
    transform-origin: 460px 1180px;
    animation: ${plantAnimation} ease-in-out 4s infinite;
  }
`;

const RatesPage = ({ location: { pathname } }) => {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <SEO title={t("Rates")} path={pathname} />
      <Section id={"rates"}>
        <Content>
          <FeatureDuo>
            <Text>
              <Headline large>
                <Trans>Let's talk money</Trans>
              </Headline>
              <Subline>
                {/* prettier-ignore */}
                <Trans>
                  For UK and international clients, I generally follow the current <TextLinkExternal href={LINK_GRAVY}>Equity rates</TextLinkExternal>. For German clients, I charge according to the <TextLinkExternal href={LINK_SPRECHERPREISE}>VDS rates</TextLinkExternal>.
                </Trans>
              </Subline>
              <Subline>
                {/* prettier-ignore */}
                <Trans>
                But every client and every project is different. Please do contact me for a custom quote tailored to your specific project needs. I'm sure we can agree on a budget that works for us both!
                </Trans>
              </Subline>
            </Text>
            <Visualization>
              <SarahRates />
            </Visualization>
          </FeatureDuo>
        </Content>
        <WaveFooter />
      </Section>
    </BaseLayout>
  );
};

export default RatesPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "rates"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
